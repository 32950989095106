import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom'
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Logo from '../../images/logo/full_large.png'
import Home from '@mui/icons-material/Home';
import Web from '@mui/icons-material/Web';
import Speaker from '@mui/icons-material/Speaker';
import CodeIcon from '@mui/icons-material/Code';
import Email from '@mui/icons-material/Email';
import Info from '@mui/icons-material/Info';

const NavTop = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropdownsOpen, setDropdownsOpen] = useState({'websites': false})
  const location = useLocation()

  let navList = (
    <List>
      <ListItem button component={Link} to="/" selected={"/" === location.pathname}>
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem
        button
        selected={/^\/websites*/.test(location.pathname)}
        onClick={() => setDropdownsOpen({...dropdownsOpen, websites: !dropdownsOpen['websites']} )}
      >
        <ListItemIcon>
          <Web />
        </ListItemIcon>
        <ListItemText primary="Websites" />
        {dropdownsOpen['websites'] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={dropdownsOpen['websites']} sx={{paddingLeft: '1em'}}>
        <List>
          <ListItem button component={Link} to="/websites" selected={/^\/websites$/.test(location.pathname)} >
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="Information" />
          </ListItem>
          <ListItem button component={Link} to="/websites/portfolio" selected={/^\/websites\/portfolio$/.test(location.pathname)} >
            <ListItemIcon>
              <Web />
            </ListItemIcon>
            <ListItemText primary="Portfolio" />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button component={Link} to="/entertainment" selected={/^\/entertainment*/.test(location.pathname)}>
        <ListItemIcon>
          <Speaker />
        </ListItemIcon>
        <ListItemText primary="Entertainment" />
      </ListItem>

      <ListItem button component={Link} to="/software" selected={/^\/software*/.test(location.pathname)}>
        <ListItemIcon>
          <CodeIcon />
        </ListItemIcon>
        <ListItemText primary="Software Development" />
      </ListItem>

      <ListItem button component={Link} to="/contact" selected={"/contact" === location.pathname}>
        <ListItemIcon>
          <Email />
        </ListItemIcon>
        <ListItemText primary="Contact" />
      </ListItem>
    </List>
  )

  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar style={{paddingRight: '0.75em'}}>
          <div style={{flexGrow: 1}}>
            <img
              src={Logo}
              alt="Zyndex Logo Full"
              style={{
                height: '3em',
                verticalAlign: 'middle',
                backgroundColor: 'white',
                borderRadius: '0.3em',
                padding: '0.1em'
              }}/>
          </div>
          <IconButton onClick={() => setDrawerOpen(true)} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => setDrawerOpen(false)}
        >
          {navList}
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

export default NavTop
