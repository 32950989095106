import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  return (
    <Grid container spacing={2} padding={4} justifyContent="space-between">
      <Grid item xs={6} sm='auto'>
        <Button variant="outlined" href="https://www.facebook.com/zyndexofficial" target="_blank" rel="noopener" startIcon={<FacebookIcon />}>
          Facebook
        </Button>
      </Grid>
      <Grid item xs='auto' sm='auto'>
        <Button variant="outlined" href="https://www.twitter.com/zyndex" target="_blank" rel="noopener" startIcon={<TwitterIcon />}>
          Twitter
        </Button>
      </Grid>
      <Grid item xs={6} sm='auto'>
        <Button variant="outlined" href="https://www.instagram.com/zyndex" target="_blank" rel="noopener" startIcon={<InstagramIcon />}>
          Instagram
        </Button>
      </Grid>
      <Grid item xs='auto' sm='auto'>
        <Button variant="outlined" href="mailto:contact@zyndex.co.uk" target="_blank" rel="noopener" startIcon={<EmailIcon />}>
          Email
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          &copy; 2015 - {new Date().getFullYear()} | Zyndex
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer
