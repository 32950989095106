import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Card, CardContent } from '@mui/material';

class Contact extends Component {
  constructor(props) {
    super(props)
    this.props.setTitle("Contact")
  }

  render() {
    return (
      <Grid container spacing={4} padding={4}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Contact
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography paragraph>
                Please feel free to contact us however you prefer.
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Button href="https://www.facebook.com/zyndexofficial" target="_blank" rel="noopener" startIcon={<FacebookIcon />}>
                    @zyndexofficial
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button href="https://www.twitter.com/zyndex" target="_blank" rel="noopener" startIcon={<TwitterIcon />}>
                    @zyndex
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button href="https://www.instagram.com/zyndex" target="_blank" rel="noopener" startIcon={<InstagramIcon />}>
                    @zyndex
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button href="mailto:contact@zyndex.co.uk" target="_blank" rel="noopener" startIcon={<EmailIcon />}>
                    contact@zyndex.co.uk
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Contact
