import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavTop from './components/nav/Top';
import NavBottom from './components/nav/Bottom';
import Footer from './components/Footer'
import Home from './components/pages/Home';
import Websites from './components/pages/Websites';
import Entertainment from './components/pages/Entertainment';
import Software from './components/pages/Software';
import Contact from './components/pages/Contact';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const setTitle = (title) => document.title = title + " | Zyndex"
  const colour_orange = '#fc6623'
  // grey1: #404040
  // grey2: #F2F2F2

  let theme = createTheme()
  theme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: colour_orange,
      },
    },
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: theme.spacing(2)
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'initial',
            color: prefersDarkMode ? null : 'initial'
          },
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box maxWidth='80rem' margin='0 auto' display='flex' flexDirection='column' minHeight='100vh' marginBottom='2.5em'>
          <NavTop />

          <Box flexGrow={1}>
            <Routes>
              <Route path="/" element={<Home setTitle={setTitle} />} />
              <Route path="websites/*" element={<Websites setTitle={setTitle} />} />
              <Route path="entertainment" element={<Entertainment setTitle={setTitle} />} />
              <Route path="software" element={<Software setTitle={setTitle} />} />
              <Route path="contact" element={<Contact setTitle={setTitle} />} />
            </Routes>
          </Box>

          <Footer />
          <NavBottom />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
