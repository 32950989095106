import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RRLink } from 'react-router-dom'
import ImageBanner from '../../images/banners/3.png'
import { Card, CardContent } from '@mui/material';

const Software = (props) => {
  props.setTitle("Software Development")

  return (
    <Grid container spacing={4} padding={4}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Software Development
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography paragraph>
              We focus mainly on developing professional software products and services. This includes websites, iOS/Android applications, desktop software and more.
            </Typography>

            <Typography>
              Just like our websites, we use Google Cloud, AWS as well as smaller providers (depending on the requirements of your business) which allows you to benefit from the scalability, redundancy, reliability and potential cost saving of these services without having to worry about management.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Price
            </Typography>

            <Typography>
              The cost of creating/maintaining software varies for the specific features required and the necessary development time. For a quote and more information, please <Link component={RRLink} to="/contact">contact us</Link>.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <img src={ImageBanner} width='100%' alt="Hardware and software support." />
      </Grid>
    </Grid>
  );
}

export default Software
