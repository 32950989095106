import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RRLink } from 'react-router-dom'
import ImageBanner from '../../images/banners/2.png'
import { Card, CardContent } from '@mui/material';

const Entertainment = (props) => {
  props.setTitle("Entertainment")

  return (
    <Grid container spacing={4} padding={4}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Entertainment
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography paragraph>
              As well as software development, Zyndex also offers an entertainment service. This includes sound and lighting with effects for events ranging from small family parties to theatre productions.
            </Typography>

            <Typography paragraph>
              Due to the expense and quality of the equipment, only we will be using it (our equipment is not for rental). If you wish to use your own media player (e.g. iPod), please let us know so we can ensure your device is supported.
            </Typography>

            <Typography>
              Please note, we do not host an event, we are happy to make general announcements but we focus on the technical side of things.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Equipment
            </Typography>

            <Typography>
              Here at Zyndex we are committed to providing you with the best experience. As a result, for each event we attend we will individually tailor what equipment we bring. It is best to contact us to discuss your requirements, for example, the size of the location and what type of event it is.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Price
            </Typography>

            <Typography>
              For more information and inquiries please <Link component={RRLink} to="/contact">contact us</Link>.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <img src={ImageBanner} width='100%' alt="Discos, weddings, parties, celebrations and more." />
      </Grid>
    </Grid>
  );
}

export default Entertainment