import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RRLink } from 'react-router-dom'
import { Card, CardContent } from '@mui/material';

const Information = (props) => {
  props.setTitle("Information")

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography paragraph>
              Zyndex is a software development company offering website solutions for businesses and personal individuals. Through our skills and expertise, we are able to create efficient, user-friendly websites tailored to your business theme/style.
            </Typography>

            <Typography paragraph>
              Our sites are created using latest and greatest languages and frameworks to suit your website. This includes, but is not limited to, React, Material UI, Bootstrap, PHP, MySQL, NoSQL etc. This allows us to provide great user interaction for desktops, tablets and mobiles (so none of that zooming or difficult scrolling nonsense). Our websites aim to provide an easy and pleasant experience for the user.
            </Typography>

            <Typography paragraph>
              All the websites we create and maintain are coded (using the languages/frameworks mentioned above) by ourselves, not external programs. This allows us to be more flexible and can lead to a quicker more efficient website.
            </Typography>

            <Typography paragraph>
              We use Google Cloud, AWS as well as smaller hosting providers, depending on the requirements of your business which allows you to benefit from the scalability, redundancy, reliability and potential cost saving of these services without having to worry about management.
            </Typography>

            <Typography>
              View our <Link component={RRLink} to="/websites/portfolio">portfolio</Link>.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Price
            </Typography>
            <Typography>
              The cost of creating/maintaining a website varies for the specific features required and the necessary development time. For a quote and more information, please <Link component={RRLink} to="/contact">contact us</Link>.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

export default Information
