import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Route, Routes } from "react-router-dom";
import Information from './Information'
import Portfolio from './Portfolio'
import ImageBanner from '../../../images/banners/1.png'

const Websites = (props) => {
  const setTitle = (title) => props.setTitle(title + " | Websites");

  return (
    <Grid container spacing={4} padding={4}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Websites
        </Typography>
      </Grid>

      <Routes>
        <Route path="/" element={<Information setTitle={setTitle} />} />
        <Route path="portfolio" element={<Portfolio setTitle={setTitle} />} />
      </Routes>

      <Grid item xs={12}>
        <img src={ImageBanner} width='100%' alt="Website design and development." />
      </Grid>
    </Grid>
  );
}

export default Websites
