import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RRLink } from 'react-router-dom'
import Carousel from "nuka-carousel";
import ImageBanner1 from '../../images/banners/1.png'
import ImageBanner2 from '../../images/banners/2.png'
import ImageBanner3 from '../../images/banners/3.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Home = (props) => {
  props.setTitle("Home")

  return (
    <React.Fragment>
      <Carousel
        transitionMode='fade'
        withoutControls
        swiping
        pauseOnHover={false}
        autoplay
        wrapAround
      >
        <img
          onLoad={() => {window.dispatchEvent(new Event('resize'));}}
          alt="Website design and development."
          src={ImageBanner1}
        />
        <img
          alt="Discos, weddings, celebrations and more."
          src={ImageBanner2}
        />
        <img
          alt="Hardware and software support."
          src={ImageBanner3}
        />
      </Carousel>
      <Grid container spacing={4} padding={4}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Websites
              </Typography>
              <Typography>
                Zyndex is a software development company offering website solutions for businesses and personal individuals. Through our skills and expertise, we are able to create efficient, user-friendly websites tailored to your business theme/style.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Entertainment
              </Typography>
              <Typography>
                Zyndex also offers a range of technical entertainment services which include lighting, sound and effects for events ranging from small family parties to theatre productions.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Software Development
              </Typography>
              <Typography>
                We focus mainly on developing professional software products and services. This includes websites, iOS/Android applications, desktop software and more.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography>
                For more information please <Link component={RRLink} to="/contact">contact us</Link>.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Home;
