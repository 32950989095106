import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Web from '@mui/icons-material/Web';
import { Card, CardContent } from '@mui/material';

const Portfolio = (props) => {
  props.setTitle("Portfolio")

  return (
    <Grid item xs={12}>
      <Typography variant="h4" gutterBottom>
        Portfolio
      </Typography>

      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Button href="https://www.spacexdata.info" target="_blank" rel="noopener" startIcon={<Web />}>
                SpaceX Data.Info
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button href="https://www.charlesomer.co.uk" target="_blank" rel="noopener" startIcon={<Web />}>
                Charles Omer
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button href="https://www.primrosecottagecaravanpark.co.uk" target="_blank" rel="noopener" startIcon={<Web />}>
                Primrose Cottage Caravan Park
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button href="http://www.1stwickfordscouts.org" target="_blank" rel="noopener" startIcon={<Web />}>
              1st Wickford Scouts
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button href="http://www.allaboutthebants.com" target="_blank" rel="noopener" startIcon={<Web />}>
                All About The Bants
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Portfolio
