import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link, useLocation } from 'react-router-dom';
import Home from '@mui/icons-material/Home';
import Web from '@mui/icons-material/Web';
import CodeIcon from '@mui/icons-material/Code';
import Email from '@mui/icons-material/Email';

const NavBottom = () => {
  const location = useLocation()
  const url = location.pathname.split("/")

  return (
    <BottomNavigation
      showLabels
      value={"/" + url[1]}
      sx={{
        position: 'fixed',
        width: '100%',
        maxWidth: '80rem',
        bottom: '0'
      }}
    >
      <BottomNavigationAction component={Link} value="/" to="/" label="Home" icon={<Home />} />
      <BottomNavigationAction component={Link} value="/websites" to="/websites" label="Websites" icon={<Web />} />
      <BottomNavigationAction component={Link} value="/software" to="/software" label="Software" icon={<CodeIcon />} />
      <BottomNavigationAction component={Link} value="/contact" to="/contact" label="Contact" icon={<Email />} />
    </BottomNavigation>
  );
}

export default NavBottom